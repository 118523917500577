
import { defineComponent } from "vue";
import "jquery/dist/jquery.min.js";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";
import $ from "jquery";
import AddBanks from "@/components/modals/AddBanks.vue";
import UpdateBank from "@/components/modals/UpdateBank.vue";

export default defineComponent({
  name: "admin-banks-table",
  components: { UpdateBank, AddBanks },
  props: {
    widgetClasses: String,
  },
  data() {
    return {
      banks: [],
      name: "",
      code: "",
      publicId: "",
    };
  },
  created() {
    this.getbanks();
  },
  methods: {
    getbanks() {
      //Fetch list of banks in Nigeria
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.get(variables.BANK_ROUTE)
          .then(({ data }) => {
            //Assign data to form fields
            this.banks = data.data;
            setTimeout(() => {
              this.displayDatatable();
            }, 3000);
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    formatDate(dateString) {
      return variables.formatDate(dateString);
    },
    deleteBank(publicId) {
      if (JwtService.getToken()) {
        ApiService.setHeader();
        ApiService.delete(`${variables.BANK_ROUTE}/${publicId}`)
          .then(({ data }) => {
            variables.toastAlert(data.data, "success");
            this.getbanks();
          })
          .catch(function(error) {
            variables.toastAlert(error.response.data.error, "error");
          });
      }
    },
    bankProps(publicId, name, code) {
      this.publicId = publicId;
      this.name = name;
      this.code = code;
    },
    displayDatatable() {
      setTimeout(() => {
        //Add jQuery datatable
        $("#basicExample").DataTable({
          iDisplayLength: 10,
          language: {
            searchPlaceholder: "Search...",
            sSearch: "",
            lengthMenu: "_MENU_ items/page",
            // "lengthMenu": "Display _MENU_ records per page",
            zeroRecords: "No matching records found",
            // "info": "Showing page _PAGE_ of _PAGES_",
            infoEmpty: "No records available",
            infoFiltered: "(filtered from _MAX_ total records)",
          },
          processing: true,
          destroy: true,
        });
      }, 3000);
    },
  },
});
