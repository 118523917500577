
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import variables from "@/router/api";

export default defineComponent({
  name: "bank-update-modal-component",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  props: {
    bankName: String,
    bankCode: String,
    publicId: String,
  },
  data() {
    return { name: this.bankName, sortCode: this.bankCode };
  },
  setup(props) {
    const submitButton1 = ref<HTMLElement | null>(null);

    const validationSchema = Yup.object().shape({
      name: Yup.string()
        .required()
        .label("Bank name"),
      sortCode: Yup.string()
        .required()
        .min(3)
        .max(6)
        .label("Bank code"),
    });

    const submitForm = (values, { resetForm }) => {
      if (submitButton1.value) {
        // Activate indicator
        submitButton1.value.setAttribute("data-kt-indicator", "on");

        setTimeout(() => {
          submitButton1.value?.removeAttribute("data-kt-indicator");

          if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.patch(
              `${variables.BANK_ROUTE}/${props.publicId}`,
              values,
              {
                headers: {
                  "Content-Type": "application/json",
                },
              }
            )
              .then((response) => {
                variables.toastAlert(response.data.data.message, "success");
                resetForm();
                window.location.reload();
              })
              .catch(function(error) {
                variables.toastAlert(error.response.data.error, "error");
              });
          }
        }, 2000);
      }
    };

    return {
      validationSchema,
      submitButton1,
      submitForm,
    };
  },
  watch: {
    // Watch for changes in the prop and update the local copy
    bankName(newValue) {
      this.name = newValue;
    },
    bankCode(newValue) {
      this.sortCode = newValue;
    },
  },
});
