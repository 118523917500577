
import { defineComponent, onMounted } from "vue";
import BankTable from "@/components/admin/BankTable.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "admin-banks-list",
  components: {
    BankTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageTitle("Banks");
    });
  },
  created() {
    //Set page title
    document.title = "Bank | " + process.env.VUE_APP_TITLE;
  },
});
